import { makeBetterEnum } from './betterEnum';

export const BuildingSizeUnit = makeBetterEnum('SquareFeet', 'SquareMeters');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BuildingSizeUnit =
  (typeof BuildingSizeUnit)[keyof typeof BuildingSizeUnit];

export const BuildingLeaseType = makeBetterEnum(
  'Owned',
  'Leased',
  'DownstreamLeasePayingUtilities',
  'DownstreamLeaseNotPayingUtilities',
  'DownstreamSubleasePayingUtilities',
  'DownstreamSubleaseNotPayingUtilities',
  'UpstreamSublease',
  'CoworkingOrServicedOffice',
  'Franchise',
  'SoldBuilding',
  'Unknown'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BuildingLeaseType =
  (typeof BuildingLeaseType)[keyof typeof BuildingLeaseType];

export const BuildingEditState = makeBetterEnum(
  'Partial',
  'Full',
  'NeedsConfirmation'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BuildingEditState =
  (typeof BuildingEditState)[keyof typeof BuildingEditState];

export const BuildingPeriodKind = makeBetterEnum(
  'BuildingSize',
  'CleanPower',
  'IsBuildingEmpty',
  'CustomField',
  'PowerUsageEffectiveness',
  'OrgUnit'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BuildingPeriodKind =
  (typeof BuildingPeriodKind)[keyof typeof BuildingPeriodKind];
