import { ReactNode, RefObject } from 'react';
import { useRouter } from 'next/router';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BoxLink from '@watershed/ui-core/components/BoxLink';
import PopOutIcon from '@watershed/icons/components/PopOut';
import ArrowLeftIcon from '@watershed/icons/components/ArrowLeft';
import { mixinSx } from '@watershed/style/styleUtils';
import SidebarRightIcon from '@watershed/icons/components/SidebarRight';
import { useViewTransition } from '@watershed/shared-frontend/hooks/useViewTransition';

export const SETTINGS_NAVIGATION_WIDTH_PX = 220;

export type NavigationItemType = {
  id: string;
  label: ReactNode;
  href: string;
  external?: boolean;
};

export type NavigationGroupType = {
  label: string;
  icon: ReactNode;
  items: Array<NavigationItemType>;
};

// Extract styles to a separate object for better reusability
const MAIN_STYLES = {
  navigationContainer: (theme: Theme, isExpanded: boolean, width: number) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width,
    paddingY: 1,
    pl: 2,
    pr: 0.5,
    backgroundColor: theme.palette.grey10,
    transition: 'transform 300ms ease-out',
    transform: 'translateX(0)',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      transform: isExpanded ? 'translateX(0)' : 'translateX(-100%)',
      borderRight: `0.5px solid ${theme.palette.grey30}`,
      pr: 1.5,
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
      boxShadow: `0px 0px 1px 0px ${theme.palette.grey100}05, 0px 0px 2px 0px ${theme.palette.grey100}05`,
    },
  }),
  collapsedStyles: (theme: Theme) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    paddingX: 2,
    paddingY: 1,
    display: 'none',
    zIndex: 900,
    backgroundColor: 'transparent',
    width: 'fit-content',

    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  }),
  itemLayout: {
    display: 'grid',
    gridTemplateColumns: '12px 1fr',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
  },
  sharedItemStyles: {
    border: 0,
    gridColumn: '2/2',
    textDecoration: 'none',
    paddingX: 1,
    paddingY: 0.5,
    borderRadius: 1,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: (theme: Theme) => `${theme.palette.grey20}80`,
    },
  },
  activeItemStyles: {
    backgroundColor: (theme: Theme) => theme.palette.grey20,
    borderRadius: 1,
  },
  backLinkStyles: {
    mt: 0.5,
    mb: 1,
    display: 'flex',
    alignItems: 'center',
    direction: 'row',
    width: 'fit-content',
    gap: 1,
    ml: -1,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '> svg': {
      fill: (theme: Theme) => theme.palette.grey50,
    },
  },
};

// Add new styles for in-page navigation
const IN_PAGE_STYLES = {
  inPageContainer: (theme: Theme) => ({
    minWidth: SETTINGS_NAVIGATION_WIDTH_PX,
    height: '100%',
    overflow: 'auto',
    position: 'sticky',
    top: 0,
    backgroundColor: `${theme.palette.grey05}80`,
  }),
  inPageSection: {
    paddingX: 1.5,
    paddingTop: 1.5,
  },
  inPageItem: {
    cursor: 'pointer',
    borderRadius: 1,
    paddingX: 1,
    paddingY: 0.5,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette.grey10,
    },
  },
  inPageItemActive: {
    backgroundColor: (theme: Theme) => theme.palette.grey20,
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette.grey20,
    },
  },
};

/**
 * A composable navigation system that provides a consistent way to build navigation interfaces.
 * Includes support for mobile-responsive behavior, breadcrumbs, and nested navigation.
 *
 * @example
 * ```tsx
 * <Navigation.Root isExpanded={isExpanded}>
 *   <Navigation.CollapsedHeader onClick={toggleExpanded}>
 *     <Navigation.Breadcrumb>
 *       <Navigation.Breadcrumb.List>
 *         <Navigation.Breadcrumb.Item>
 *           <Navigation.Breadcrumb.Text>Home</Navigation.Breadcrumb.Text>
 *         </Navigation.Breadcrumb.Item>
 *         <Navigation.Breadcrumb.Separator />
 *         <Navigation.Breadcrumb.Item>
 *           <Navigation.Breadcrumb.Icon>{icon}</Navigation.Breadcrumb.Icon>
 *           <Navigation.Breadcrumb.Text>Settings</Navigation.Breadcrumb.Text>
 *         </Navigation.Breadcrumb.Item>
 *       </Navigation.Breadcrumb.List>
 *     </Navigation.Breadcrumb>
 *   </Navigation.CollapsedHeader>
 *
 *   <Navigation.BackLink onClick={handleBack}>
 *     <Navigation.Label>Back</Navigation.Label>
 *   </Navigation.BackLink>
 *
 *   <Navigation.Group icon={<Icon />} label="Group">
 *     <Navigation.Item href="/path">
 *       <Navigation.Label>Item</Navigation.Label>
 *     </Navigation.Item>
 *   </Navigation.Group>
 * </Navigation.Root>
 */
function Navigation({
  children,
  isExpanded,
  width = SETTINGS_NAVIGATION_WIDTH_PX,
  ref,
}: {
  children: ReactNode;
  isExpanded: boolean;
  width?: number;
  ref?: RefObject<HTMLDivElement>;
}) {
  return (
    <Stack
      component="nav"
      ref={ref}
      gap={1}
      sx={(theme) => MAIN_STYLES.navigationContainer(theme, isExpanded, width)}
    >
      {children}
    </Stack>
  );
}

/**
 * Container for navigation header content with icon and label.
 *
 * @example
 * ```tsx
 * <Navigation.Header icon={<Icon />}>Section Title</Navigation.Header>
 * ```
 */
function NavigationHeader({
  icon,
  children,
}: {
  icon: ReactNode;
  children: ReactNode;
}) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box sx={MAIN_STYLES.iconContainer}>{icon}</Box>
      <Typography
        variant="caption"
        sx={{
          color: (theme) => theme.palette.grey50,
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
}

/**
 * A back link component with an arrow icon, typically used in the header.
 *
 * @example
 * ```tsx
 * <Navigation.BackLink onClick={() => navigate(-1)}>
 *   <Navigation.Label>Back to Dashboard</Navigation.Label>
 * </Navigation.BackLink>
 * ```
 */
function NavigationBackLink({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) {
  return (
    <BoxLink
      onClick={onClick}
      sx={mixinSx(MAIN_STYLES.backLinkStyles, MAIN_STYLES.sharedItemStyles)}
    >
      <Box sx={MAIN_STYLES.iconContainer}>
        <ArrowLeftIcon
          size={14}
          color={(theme) => theme.palette.text.secondary}
        />
      </Box>
      {children}
    </BoxLink>
  );
}

/**
 * A section component that provides the layout for navigation items.
 */
function NavigationItemContainer({
  children,
  onClick,
  href,
  isActive,
}: {
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  isActive?: boolean;
}) {
  return (
    <Box sx={MAIN_STYLES.itemLayout}>
      <BoxLink
        href={href}
        onClick={onClick}
        sx={mixinSx(
          MAIN_STYLES.sharedItemStyles,
          isActive && MAIN_STYLES.activeItemStyles
        )}
      >
        {children}
      </BoxLink>
    </Box>
  );
}

/**
 * A label component for navigation items.
 */
function NavigationLabel({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Typography
      variant="h4"
      sx={{
        color: (theme) => theme.palette.text.secondary,
      }}
    >
      {children}
    </Typography>
  );
}

/**
 * A navigation item that renders as a link with optional external indicator.
 * Supports view transitions for internal navigation.
 *
 * @example
 * ```tsx
 * <Navigation.Item href="/settings">
 *   <Navigation.Label>Settings</Navigation.Label>
 * </Navigation.Item>
 *
 * <Navigation.Item href="https://docs.example.com" external>
 *   <Navigation.Label>Documentation</Navigation.Label>
 * </Navigation.Item>
 * ```
 */
function NavigationItem({
  href,
  external,
  onClick,
  children,
}: {
  href: string;
  external?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children: ReactNode;
}) {
  const router = useRouter();
  const startViewTransition = useViewTransition();
  const isActive = router.pathname === href;

  const handleClick = (e: React.MouseEvent) => {
    // If it's an external link or there's a custom onClick handler, skip the view transition
    if (external || onClick) {
      onClick?.(e);
      return;
    }

    // Prevent default navigation
    e.preventDefault();

    // If view transitions are supported, use them
    if (startViewTransition) {
      startViewTransition(() => {
        void router.push(href);
      });
    } else {
      // Fall back to regular navigation
      void router.push(href);
    }
  };

  return (
    <NavigationItemContainer
      href={href}
      onClick={handleClick}
      isActive={isActive}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {children}
        {external ? (
          <PopOutIcon
            size={14}
            color={(theme) => theme.palette.text.secondary}
          />
        ) : null}
      </Stack>
    </NavigationItemContainer>
  );
}

/**
 * A group component that contains navigation items with a header.
 *
 * @example
 * ```tsx
 * <Navigation.Group icon={<SettingsIcon />} label="Settings">
 *   <Navigation.Item href="/settings/profile">
 *     <Navigation.Label>Profile</Navigation.Label>
 *   </Navigation.Item>
 * </Navigation.Group>
 * ```
 */
function NavigationGroup({
  label,
  icon,
  children,
}: {
  label: ReactNode;
  icon: ReactNode;
  children: ReactNode;
}) {
  return (
    <Stack>
      <NavigationHeader icon={icon}>{label}</NavigationHeader>
      <Stack gap={0.25} marginTop={0.5} marginBottom={1}>
        {children}
      </Stack>
    </Stack>
  );
}

/**
 * A collapsed header component that appears when the navigation is collapsed.
 * Typically used for mobile views to allow expanding the navigation.
 */
function NavigationCollapsedHeader({
  onClick,
  children,
  ref,
}: {
  onClick: () => void;
  children: ReactNode;
  ref?: RefObject<HTMLDivElement>;
}) {
  return (
    <Box ref={ref} sx={MAIN_STYLES.collapsedStyles}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Box
          onClick={onClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            p: 1,
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: (theme) => `${theme.palette.grey20}`,
            },
          }}
        >
          <SidebarRightIcon size={16} color={(theme) => theme.palette.grey50} />
        </Box>
        <Stack sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
}

type NavigationBreadcrumbType = {
  ({ children, ...props }: { children: ReactNode }): JSX.Element;
  List: typeof NavigationBreadcrumbList;
  Item: typeof NavigationBreadcrumbItem;
  Text: typeof NavigationBreadcrumbText;
  Icon: typeof NavigationBreadcrumbIcon;
  Separator: typeof NavigationBreadcrumbSeparator;
};

/**
 * A breadcrumb system that provides hierarchical navigation.
 * Built as a compound component for maximum flexibility.
 *
 * @example Simple usage
 * ```tsx
 * <Navigation.Breadcrumb>
 *   <Navigation.Breadcrumb.List>
 *     <Navigation.Breadcrumb.Item>
 *       <Navigation.Breadcrumb.Text>Home</Navigation.Breadcrumb.Text>
 *     </Navigation.Breadcrumb.Item>
 *   </Navigation.Breadcrumb.List>
 * </Navigation.Breadcrumb>
 * ```
 *
 * @example With icons and separators
 * ```tsx
 * <Navigation.Breadcrumb>
 *   <Navigation.Breadcrumb.List>
 *     <Navigation.Breadcrumb.Item>
 *       <Navigation.Breadcrumb.Text>Home</Navigation.Breadcrumb.Text>
 *     </Navigation.Breadcrumb.Item>
 *     <Navigation.Breadcrumb.Separator />
 *     <Navigation.Breadcrumb.Item>
 *       <Navigation.Breadcrumb.Icon>
 *         <SettingsIcon />
 *       </Navigation.Breadcrumb.Icon>
 *       <Navigation.Breadcrumb.Text>Settings</Navigation.Breadcrumb.Text>
 *     </Navigation.Breadcrumb.Item>
 *   </Navigation.Breadcrumb.List>
 * </Navigation.Breadcrumb>
 * ```
 */
const NavigationBreadcrumb = Object.assign(
  function NavigationBreadcrumb({
    children,
    ...props
  }: { children: ReactNode }) {
    return (
      <nav aria-label="Navigation breadcrumb" {...props}>
        {children}
      </nav>
    );
  },
  {
    List: NavigationBreadcrumbList,
    Item: NavigationBreadcrumbItem,
    Text: NavigationBreadcrumbText,
    Icon: NavigationBreadcrumbIcon,
    Separator: NavigationBreadcrumbSeparator,
  }
) as NavigationBreadcrumbType;

/**
 * Container for breadcrumb items. Handles layout and spacing.
 *
 * @example
 * ```tsx
 * <Navigation.Breadcrumb.List>
 *   <Navigation.Breadcrumb.Item>...</Navigation.Breadcrumb.Item>
 *   <Navigation.Breadcrumb.Separator />
 *   <Navigation.Breadcrumb.Item>...</Navigation.Breadcrumb.Item>
 * </Navigation.Breadcrumb.List>
 * ```
 */
function NavigationBreadcrumbList({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      component="ol"
      sx={{
        p: 0,
        m: 0,
        '& > li:last-child span': {
          color: (theme) => theme.palette.text.secondary,
        },
      }}
    >
      {children}
    </Stack>
  );
}

/**
 * Individual breadcrumb item container. Groups related elements.
 *
 * @example
 * ```tsx
 * <Navigation.Breadcrumb.Item>
 *   <Navigation.Breadcrumb.Icon>{icon}</Navigation.Breadcrumb.Icon>
 *   <Navigation.Breadcrumb.Text>Label</Navigation.Breadcrumb.Text>
 * </Navigation.Breadcrumb.Item>
 * ```
 */
function NavigationBreadcrumbItem({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Stack direction="row" gap={1} alignItems="center" component="li">
      {children}
    </Stack>
  );
}

/**
 * Text element in a breadcrumb item. Handles typography styles.
 * The last breadcrumb text in the list will automatically use text.secondary color.
 *
 * @example
 * ```tsx
 * <Navigation.Breadcrumb.Text>Settings</Navigation.Breadcrumb.Text>
 * ```
 */
function NavigationBreadcrumbText({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Typography
      component="span"
      variant="h4"
      color={(theme) => theme.palette.grey50}
    >
      {children}
    </Typography>
  );
}

/**
 * Icon container in a breadcrumb item. Handles alignment and accessibility.
 *
 * @example
 * ```tsx
 * <Navigation.Breadcrumb.Icon>
 *   <SettingsIcon size={16} />
 * </Navigation.Breadcrumb.Icon>
 * ```
 */
function NavigationBreadcrumbIcon({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box
      component="span"
      sx={{ display: 'flex', alignItems: 'center' }}
      aria-hidden="true"
    >
      {children}
    </Box>
  );
}

/**
 * Separator between breadcrumb items. Renders as a forward slash by default.
 *
 * @example
 * ```tsx
 * <Navigation.Breadcrumb.Item>Home</Navigation.Breadcrumb.Item>
 * <Navigation.Breadcrumb.Separator />
 * <Navigation.Breadcrumb.Item>Settings</Navigation.Breadcrumb.Item>
 * ```
 */
function NavigationBreadcrumbSeparator() {
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        color: (theme) => theme.palette.grey50,
      }}
      aria-hidden="true"
    >
      /
    </Box>
  );
}

/**
 * In-page navigation container component.
 */
function NavigationInPage({
  children,
  ref,
}: {
  children: ReactNode;
  ref?: RefObject<HTMLDivElement>;
}) {
  return (
    <Stack
      ref={ref}
      component="nav"
      gap={1}
      sx={IN_PAGE_STYLES.inPageContainer}
    >
      {children}
    </Stack>
  );
}

/**
 * In-page navigation section component.
 */
function NavigationInPageSection({
  category,
  children,
}: {
  category?: string;
  children: ReactNode;
}) {
  return (
    <Stack gap={1} sx={IN_PAGE_STYLES.inPageSection}>
      {category && (
        <Typography color="grey50" variant="caption">
          {category}
        </Typography>
      )}
      <Stack gap={0.25}>{children}</Stack>
    </Stack>
  );
}

/**
 * In-page navigation item component.
 */
function NavigationInPageItem({
  sectionId,
  sectionTitle,
  isActive,
  onClick,
}: {
  sectionId: string;
  sectionTitle: string;
  isActive?: boolean;
  onClick: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      id={sectionId}
      sx={mixinSx(
        IN_PAGE_STYLES.inPageItem,
        isActive && IN_PAGE_STYLES.inPageItemActive
      )}
    >
      <Typography variant="h4" color="text.secondary">
        {sectionTitle}
      </Typography>
    </Box>
  );
}

type NavigationType = {
  Root: typeof Navigation;
  Header: typeof NavigationHeader;
  BackLink: typeof NavigationBackLink;
  Group: typeof NavigationGroup;
  Item: typeof NavigationItem;
  Label: typeof NavigationLabel;
  CollapsedHeader: typeof NavigationCollapsedHeader;
  Breadcrumb: typeof NavigationBreadcrumb & {
    List: typeof NavigationBreadcrumbList;
    Item: typeof NavigationBreadcrumbItem;
    Text: typeof NavigationBreadcrumbText;
    Icon: typeof NavigationBreadcrumbIcon;
    Separator: typeof NavigationBreadcrumbSeparator;
  };
  InPage: typeof NavigationInPage;
  InPageSection: typeof NavigationInPageSection;
  InPageItem: typeof NavigationInPageItem;
};

// Attach the subcomponents to Navigation
Navigation.Root = Navigation;
Navigation.Header = NavigationHeader;
Navigation.BackLink = NavigationBackLink;
Navigation.Group = NavigationGroup;
Navigation.Item = NavigationItem;
Navigation.Label = NavigationLabel;
Navigation.CollapsedHeader = NavigationCollapsedHeader;
Navigation.Breadcrumb = NavigationBreadcrumb;
Navigation.Breadcrumb.List = NavigationBreadcrumbList;
Navigation.Breadcrumb.Item = NavigationBreadcrumbItem;
Navigation.Breadcrumb.Text = NavigationBreadcrumbText;
Navigation.Breadcrumb.Icon = NavigationBreadcrumbIcon;
Navigation.Breadcrumb.Separator = NavigationBreadcrumbSeparator;
Navigation.InPage = NavigationInPage;
Navigation.InPageSection = NavigationInPageSection;
Navigation.InPageItem = NavigationInPageItem;

export default Navigation as NavigationType;
