import { useRouter } from 'next/router';
import { useViewTransition } from '@watershed/shared-frontend/hooks/useViewTransition';
import { useFeatureFlag } from '../../../../utils/FeatureFlag';
import { Flags } from '@watershed/constants/flags';

/**
 * Transforms a route to its v2 version and provides navigation utilities
 */
export function useRedirectForVersion() {
  const router = useRouter();
  const startViewTransition = useViewTransition();
  const isFeatureEnabled = useFeatureFlag(Flags.EntFoundSettingsV2);

  const getV2Route = (baseRoute: string) =>
    baseRoute.replace('/settings/', '/settings/v2/');

  const getNonV2Route = (baseRoute: string) =>
    baseRoute.replace('/settings/v2/', '/settings/');

  const redirectToV2 = async (baseRoute: string) => {
    // If feature flag is disabled, redirect to non-v2 version instead
    const targetRoute = isFeatureEnabled
      ? getV2Route(baseRoute)
      : getNonV2Route(getV2Route(baseRoute)); // Convert to v2 then back to ensure consistent behavior

    if (startViewTransition) {
      startViewTransition(() => {
        void router.push(targetRoute);
      });
    } else {
      await router.push(targetRoute);
    }
  };

  return { redirectToV2, getV2Route };
}
