import LoggedInLayout from './LoggedInLayout';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../components/ErrorFallback';
import { SkipNavContent } from '../components/navigation/SkipNav';
import { SettingsLayout as Settings } from '../components/settings/v2/components/SettingsLayout';
import SettingsNavigation from '../components/settings/v2/components/navigation/SettingsNavigation';
import { NavigationProvider } from '../components/settings/v2/components/navigation/NavigationContext';

export default function SettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <LoggedInLayout>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.grey10,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100dvh',
          overflow: 'hidden',
        }}
      >
        <NavigationProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SettingsNavigation />
          </ErrorBoundary>
          <Settings.Layout>
            <SkipNavContent />
            {children}
          </Settings.Layout>
        </NavigationProvider>
      </Box>
    </LoggedInLayout>
  );
}
