import { GQCompanySbtCommitmentStage } from '@watershed/shared-universal/generated/graphql-schema-types';

import { GQCompanyClimateCommitmentRawAllFieldsFragment } from '@watershed/shared-universal/generated/graphql';

export function getSbtCommitmentStageFromCommitments(
  commitments: Array<
    Pick<
      GQCompanyClimateCommitmentRawAllFieldsFragment,
      'sbtCommitmentStage' | 'kind'
    >
  >
): GQCompanySbtCommitmentStage {
  const sbtCommitments = commitments.filter(
    (commitment) => commitment.kind === 'ScienceBasedTarget'
  );
  const committed = sbtCommitments.find(
    (commitment) => commitment.sbtCommitmentStage === 'Committed'
  );
  const targetsSet = sbtCommitments.find(
    (commitment) => commitment.sbtCommitmentStage === 'TargetsSet'
  );
  const removed = sbtCommitments.find(
    (commitment) => commitment.sbtCommitmentStage === 'Removed'
  );

  if (removed) {
    return GQCompanySbtCommitmentStage.Removed;
  } else if (targetsSet) {
    return GQCompanySbtCommitmentStage.TargetsSet;
  } else if (committed) {
    return GQCompanySbtCommitmentStage.Committed;
  }
  return GQCompanySbtCommitmentStage.None;
}
