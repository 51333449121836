import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { Theme, SxProps } from '@mui/material';
import { SETTINGS_NAVIGATION_WIDTH_PX } from './core/Navigation';

const layoutStyles: SxProps<Theme> = (theme) => ({
  display: 'flex',
  // Used to center the loading spinner on page load.
  alignItems: 'center',
  justifyContent: 'center',
  outline: `0.5px solid ${theme.palette.grey30}`,
  backgroundColor: theme.palette.grey05,
  borderRadius: '6px',
  margin: 1,
  marginLeft: `${SETTINGS_NAVIGATION_WIDTH_PX + 8}px`,
  height: 'calc(100dvh - 16px)',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
  boxShadow: `0px 1px 2px 0px ${theme.palette.grey100}10, 0px 0px 24px -2px ${theme.palette.grey100}05`,

  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 6,
    outline: `0.5px solid ${theme.palette.grey30}`,
    height: '100%',
  },
});

type SettingsLayoutProps = {
  children: ReactNode;
};

function Layout({ children }: SettingsLayoutProps) {
  return (
    <Box component="main" sx={layoutStyles}>
      {children}
    </Box>
  );
}

export const SettingsLayout = {
  Layout,
};
