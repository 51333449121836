import { makeBetterEnum } from './betterEnum';

export const FinanceAuditAction = makeBetterEnum(
  'BulkImport',
  'SidebarUpdate',
  'CreateAssetDialog',
  'APIRequest',
  'AssetPageUpdate',
  'EstimateEmissions',
  'CompanyMatchUpdate',
  'EngagementTask',
  'BulkUpdateAssetFromFlags',
  'WatershedUpdate'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FinanceAuditAction =
  (typeof FinanceAuditAction)[keyof typeof FinanceAuditAction];

export const FinanceMetric = makeBetterEnum(
  'AssetCount',
  'EconomicIntensity',
  'EconomicIntensityScope1And2',
  'FinancedEmissions',
  'FinancedEmissionsScope1',
  'FinancedEmissionsScope1And2',
  'FinancedEmissionsScope2',
  'FinancedEmissionsScope3',
  'HoldingCount',
  'Pcaf',
  'PcafScope1',
  'PcafScope2',
  'PcafScope3',
  'RevenueIntensity',
  'RevenueIntensityScope1',
  'RevenueIntensityScope2',
  'RevenueIntensityScope3',
  'TotalEmissions',
  'TotalEmissionsScope1',
  'TotalEmissionsScope2',
  'TotalEmissionsScope3',
  'TotalOutstandingAmount',
  'Waci',
  'WaciScope1',
  'WaciScope2',
  'WaciScope3',
  'IciAlignment'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FinanceMetric = (typeof FinanceMetric)[keyof typeof FinanceMetric];

export const FinanceTagType = makeBetterEnum(
  'AssetTag',
  'AssetYearTag',
  'FundTag',
  'HoldingTag'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FinanceTagType =
  (typeof FinanceTagType)[keyof typeof FinanceTagType];

export const FundAssetClass = makeBetterEnum(
  'ListedEquity',
  'CorporateBonds',
  'UnlistedEquity',
  'BusinessLoans',
  'CommercialRealEstate',
  'Mortgages',
  'ProjectFinance',
  'AssetGroup',
  'SovereignDebt',
  'PersonalMotorVehicleInsurance',
  'CommercialLineOfInsurance'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FundAssetClass =
  (typeof FundAssetClass)[keyof typeof FundAssetClass];
