import { useMemo } from 'react';
import { Trans } from '@lingui/react/macro';
import { useRouter } from 'next/router';
import Backdrop from '@mui/material/Backdrop';
import { useMediaQuery } from '@mui/material';
import useBackToAppFromSettings from '../../hooks/useBackToAppFromSettings';
import { useUserContext } from '../../../../../utils/UserContext';
import { createSettingsNavigationGroups } from '../../data/settingsNavigationData';
import { NavigationProvider, useNavigation } from './NavigationContext';
import Navigation, { SETTINGS_NAVIGATION_WIDTH_PX } from '../core/Navigation';

function SettingsNavigationContent() {
  const { isExpanded, setIsExpanded } = useNavigation();
  const { canAccessFinance } = useUserContext();
  const { navigateToLastRoute } = useBackToAppFromSettings();
  const smallScreen = useMediaQuery('(max-width: 900px)');
  const router = useRouter();

  // Use the factory function to create navigation groups
  const settingsNavigationGroups = useMemo(
    () => createSettingsNavigationGroups({ canAccessFinance }),
    [canAccessFinance]
  );

  // Find the current page and its group based on the route
  const { currentGroup, currentPage } = useMemo(() => {
    const currentPath = router.pathname;
    for (const group of settingsNavigationGroups) {
      const page = group.items.find((item) =>
        currentPath.startsWith(item.href)
      );
      if (page) {
        return { currentGroup: group, currentPage: page };
      }
    }
    return { currentGroup: null, currentPage: null };
  }, [router.pathname, settingsNavigationGroups]);

  return (
    <>
      {/* Navigation Component - CollapsedHeader handles its own responsive display */}
      {smallScreen && (
        <Navigation.CollapsedHeader onClick={() => setIsExpanded(true)}>
          <Navigation.Breadcrumb>
            <Navigation.Breadcrumb.List>
              <Navigation.Breadcrumb.Item>
                <Navigation.Breadcrumb.Text>
                  <Trans context="Settings menu main navigation">
                    Settings
                  </Trans>
                </Navigation.Breadcrumb.Text>
              </Navigation.Breadcrumb.Item>
              {currentGroup && (
                <Navigation.Breadcrumb.Item>
                  <Navigation.Breadcrumb.Separator />
                  <Navigation.Breadcrumb.Text>
                    {currentGroup.label}
                  </Navigation.Breadcrumb.Text>
                </Navigation.Breadcrumb.Item>
              )}
              {currentPage && (
                <Navigation.Breadcrumb.Item>
                  <Navigation.Breadcrumb.Separator />
                  <Navigation.Breadcrumb.Text>
                    {currentPage.label}
                  </Navigation.Breadcrumb.Text>
                </Navigation.Breadcrumb.Item>
              )}
            </Navigation.Breadcrumb.List>
          </Navigation.Breadcrumb>
        </Navigation.CollapsedHeader>
      )}

      <Navigation.Root
        isExpanded={isExpanded}
        width={SETTINGS_NAVIGATION_WIDTH_PX}
      >
        <Navigation.BackLink onClick={navigateToLastRoute}>
          <Navigation.Label>
            <Trans context="Settings link back to the main application">
              Back to app
            </Trans>
          </Navigation.Label>
        </Navigation.BackLink>

        {/* Navigation groups */}
        {settingsNavigationGroups.map((group) => (
          <Navigation.Group
            key={group.label}
            label={group.label}
            icon={group.icon}
          >
            {group.items.map((item) => (
              <Navigation.Item
                key={item.id}
                href={item.href}
                external={item.newTab}
              >
                <Navigation.Label>{item.label}</Navigation.Label>
              </Navigation.Item>
            ))}
          </Navigation.Group>
        ))}
      </Navigation.Root>

      {/* Backdrop - this is primarily used to collapse the navigation when the user clicks on the screen */}
      {smallScreen && (
        <Backdrop
          open={isExpanded}
          onClick={() => setIsExpanded(false)}
          sx={{
            zIndex: 950,
            backgroundColor: 'transparent',
          }}
        />
      )}
    </>
  );
}

/**
 * Settings navigation component with responsive behavior handled by CSS.
 */
export default function SettingsNavigation() {
  return (
    <NavigationProvider>
      <SettingsNavigationContent />
    </NavigationProvider>
  );
}
