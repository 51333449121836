import { makeBetterEnum } from './betterEnum';
import { Teams } from './teams';

export const Flags = makeBetterEnum(
  // Rate Limiter Flags
  'ApiUploadDataRateLimiter',
  'ApiUploadDataRateLimiterLarge',
  'AssetManagerDisableSnapshotCreation',
  'AssetManagerConfigureDataQualityFlags',
  'AssetManagerCurrencyDisplay',
  'AssetManagerDataExplorerSyw',
  'AssetManagerDataFlagsFromInputModels',
  'AssetManagerDueDiligence',
  'AssetManagerEstimationMethodologyVersioning',
  'AssetManagerGroupByCompanyName',
  'AssetManagerIncludeZeroFinancedEmissions',
  'AssetManagerNegativeOutstandingAmount',
  'AssetManagerPcafScore5ValueBasedEstimation',
  'AssetManagerRenameFunds',
  'AssetManagerScope2Subscopes',
  'AssetManagerSidebarCanCollapse',
  'AssetManagerSkipFlagCreation',
  'AssetManagerSkipOrgCheckDuringCompanyMatch',
  'AssetManagerSkipSywFootprintSnapshot',
  'AssetManagerSnapshotDeletion',
  'AssetManagerViewEdciFieldsPreset',
  'AssetManagerYearOverYearComparison',
  'AssetManagerMortgagesHoldingType',
  'AssetManagerProjectFinanceAssetClass',
  'AssetManagerAlternateDataManagementDownload',
  'AssetManagerAlternateAssetYearDownload',
  'AssetManagerAlternateAssetCorporateDownload',
  'AssetManagerAutoMapping',
  'AssetManagerUseSAndPData',
  'AssetManagerUseSAndPIndustryCodes',
  'AssetManagerDisableAuditReports',
  'AssetManagerEnableAuditReports',
  'AssetManagerUseBiV2ForSnapshotGrid',
  'AssetManagerSkipLogEventCreation',
  'AssetManagerSkipSAndPBQSync',
  'AssetManagerRecordAttributionFactorOverride',
  'AssetManagerWriteIndustrySectorCodeToBart',
  'BenchmarkRevenueAtRisk',
  'BiDrilldownMultipleMeasures',
  'BiDrilldownFacilitiesMeasures',
  'BiDrilldownSupplierMeasures',
  'BigRocksUiFinance',
  'BiEnableMetricDescriptions',
  'BiReadFromActivityDataset',
  'EnablePreSetCustomTagsForBuildings',
  'EntFoundAccessSecuritySettings',
  'EntFoundTempApiSecretCompareToHash',
  'EntFoundDirectorySync',
  'EntFoundDirectorySyncWriteChanges',
  'EntFoundIdleLogout',
  'EntFoundSkilljarLinks',
  'EntFoundEmailRestrictions',
  'EntFoundSettingsV2',
  'EntFoundDisableRouteAccessRequestsToOpal',
  'EntFoundGateAdminAccess',
  'DashboardCleanPowerAdvancedEstimation',
  'DemoPlatformEnableForRestrictedOrg',
  'DisableContractorAccess',
  'DisableSalesforceSyncSupportPermissions',
  'FootprintSuppliersPage',
  'SupplierExpenseCategories',
  'SupplierEngagementSurveys',
  'SupplierFootprintUncollapse',
  'SupplyChainChartsConfigAdvancedControls',
  'SupplyChainHideArchivedConfigsFromCharts',
  'MeasurementIntegrationsQuickBooks',
  'MeasurementIntegrationsUrjanet',
  'MeasurementIntegrationsArcadia',
  'MeasurementIntegrationsNetSuite',
  'MeasurementIntegrationsMergeFinancials',
  'FootprintDisableWriteInputQuantities',
  'FootprintProductLevelEmissions',
  'FootprintShowProductModels',
  'AlwaysAllowTcfdQualitative',
  'ReportingIgnoreCdpPlanRestrictions',
  'ReportingCdpAlwaysOverwriteConflicts',
  'EmissionsModelMinimizeVariableNameInSql',
  'EnablePublishFileUploadFacilities',
  'ReduxSupplierEngagementBySpend',
  'ReduxExperimentalCalculations',
  'ReduxContributionsIntervalOptimization',
  'ReduxDisableCustomTagsInContributions',
  'ReduxUseLocationBasedEmissions',
  'TempIndividualWritesForMappings',
  'MultipleDatasourcesPerAdt',
  'DisableDataGovernancePage',
  'ActivityDataAllActivityButton',
  'ActivityDataNumericCustomColumns',
  'DataIngestionDataAuditTransformation',
  'Unknown',
  'ShouldAllowPipelineResultFootprintKind',
  'FootprintBenchmarksForNonCorporate',
  'DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission',
  'DataIngestionValueMappingsVendorRowsExpanded',
  'DataIngestionValueMappingDisableVendorPreviews',
  'ValueMappingSkipSeedingOnUpload',
  'DataIngestionDisableExportFacilitiesDocs',
  'DataIngestionColocatedDataCentersInFacilities',
  'DataIngestionEditablePowerUsageEffectiveness',
  'DataIngestionFacilitiesTracker',
  'DataIngestionTestCts',
  'DataIngestionFilefeeds',
  'DataIngestionDisableValueMappingTaskAssignment',
  'DataIngestionFacilitiesExtraLargeMode',
  'DataIngestionApiDataRegistry',
  'DataIngestionFacilitiesApprovals',
  'DataIngestionDirectEntry',
  'DataIngestionGlobalFacilitiesPage',
  'DataIngestionFacilitiesUseLoader',
  'DataIngestionAllowUploadsOnActivityDataPage',
  'DataIngestionSkipSendingApprovalFlowEmails',
  'DataIngestionImporterV3',
  'DataIngestionUpgradeImporterV1',
  'DisableFinancialMappingAiSuggestions',
  'DisableFootprintGeneration',
  'FootprintGenerationAllowPreEmissionsModelGaActivityData',
  'UseDedicatedTaskQueueForTraceAddendum',
  'DataInfraMaterializeTableInTraceAddendum',
  'LogAuditEventsToWorkOs',
  'ShouldUseTestDuckDbServer',
  'ShouldUseTestTemporalTaskQueue',
  'BulkUploadTaskManagement',
  'ContractOverrideUpsellBenchmarks',
  'SupplyChainGeneralMillsCompanyEngagementEmail',
  'CompleteFiscalYearInOverview',
  'OverrideBlockedPipelineV2Dialog',
  'DefaultDrilldownChartsClosed',
  'CalculationMethodologyCustomizationPage',
  'DataIngestionSnowfall',
  'CalculationKeepZerosInPipelineResults',
  'CalculationEnableAlphaReleases',
  'CalculationEnablePipelinesPrototype',
  'ConsumerGoodsApparelNewMethodologies',
  'ActivityDataMultipleMeasures',
  'DisableFacilitiesGeocoding',
  'ShouldUseExtraLargeDuckDbServer',
  'TurnOffCdpUpdateFeatureOnboardingDialog',
  'CsrdEnableAiExtractedAnswers',
  'CsrdEnableAiNarrativeExport',
  'CsrdEnableReportQuestionApprovals',
  'EnableFacilitiesWaterForGhg',
  'EnableFacilitiesWasteForGhg',
  'SupplyChainCustomSupplierConfig',
  'LimitLargeGroupDataLineage',
  'ByPassChonkerChecker',
  'SupplyChainAdminEditCompany',
  'SupplyChainReopenApprovedTask',
  'EnableUserUploadCountsOnMeasurementPage',
  'DataInfraCreateTempTableInCleanPower',

  // DuckDB related
  'DataInfraBumpMaxExpressionDepthFootAssembly',
  'DataInfraUseDuckDbResourceEstimation',
  'DataInfraOnlyUseDuckDbTestExtraLargeServer',
  'DataInfraOnlyUseDuckDbTestServersForResourceEstimation',
  'RebuildValueMappingContextOnDuckDbExtraLarge',
  'DataInfraUseLscriptDateCastOptimization',
  'DataInfraUseLscriptReferenceFilterOptimization',

  'DataLineageV2',
  'LocalizationDynamicStrings',
  'EnableEditProductRulesAlways',
  'OrgStructure',
  'OrgStructureMultipleVersions',
  'OrgStructureChangelog',
  'OrgStructureAutofillingEnabled',
  'UseBartForHeadcountIntensity',
  'CsrdAvoidedCleanPower',
  'ReportingTextInputRichText',
  'ReportingShowRegulatoryCheckup',
  'ReportingEnableNewClimateIntelligencePanel',
  'ReportingEnableCdpApi',
  'ReportingEnableCdpApiUnscoredDeadline',
  'ReportingShowCdpApiIsDownWarnings',
  'ReportingUseTitleSubtitleMigratedColumns',
  'ReportingFreezeConfigEdits',
  'ReportAnswerPermissions',
  'ReportPermissionManagement',
  'DataIngestionCsrdApprovalFlow',
  'ReportingUseBiV2',
  'ShowTopSuppliersTableInFootprintOverview',
  'SupplyChainTargetsInitiativesExport',
  'SupplyChainBulkSurveyResponseExport',
  'SupplyChainEmissionsExport',
  'BiDrilldownAiQuerying',
  'AutoRunFootprintOnUserUpload',
  'EnableCompanyMergingAdmin',
  'DataIngestionUserUploadEdit',
  'DataIngestionDisableOneSchemaAi',
  'OrganizationDestroyWithFkDependencies',
  'OrganizationDestroyLogEvents',
  'CsrdDataRequirementsValidation',
  'LearningHubReplaceUrls',
  'ShowYourWorkNumeratorAndDenominator',

  // FY25 temp pricing flags
  'PricingFy25ReportingModuleTemp',
  'PricingFy25BlockScope1And2Temp',
  'PricingFy25BlockAllScope3Temp',
  'PricingFy25BlockScope3_1Temp',
  'PricingFy25BlockScope3_2Temp',
  'PricingFy25BlockScope3_3Temp',
  'PricingFy25BlockScope3_4Temp',
  'PricingFy25BlockScope3_5Temp',
  'PricingFy25BlockScope3_6Temp',
  'PricingFy25BlockScope3_7Temp',
  'PricingFy25BlockScope3_8Temp',
  'PricingFy25BlockScope3_9Temp',
  'PricingFy25BlockScope3_10Temp',
  'PricingFy25BlockScope3_11Temp',
  'PricingFy25BlockScope3_12Temp',
  'PricingFy25BlockScope3_13Temp',
  'PricingFy25BlockScope3_14Temp',
  'PricingFy25ReductionsModuleTemp',
  'PricingFy25CsrdE1ModuleTemp',
  'PricingFy25CsrdE2ModuleTemp',
  'PricingFy25CsrdE3ModuleTemp',
  'PricingFy25CsrdE4ModuleTemp',
  'PricingFy25CsrdE5ModuleTemp',
  'PricingFy25CsrdSocialAndGovernanceModuleTemp',
  'PricingFy25CsrdReportBuilderModuleTemp',

  'UseBartForMonetaryIntensity',
  'UseBartForCustomIntensity',
  'EnableGeneralAnomalyDetection',
  'SendEmailsOnDemoOrTestOrg',

  'EnableUtilityFileScanning',
  'ActivityDataListShowAllSchemas',
  'ShowYourWorkTotalRow',
  'CalculationEmEvalRefactorParity',
  'CalculationEmEvalRefactorCut',
  'IgnoreFootprintRowLimit',
  'FootprintAuditBundleRowLimit',
  'ProductRulesContainsOperator',

  // Temporary to simulate failures and evaluate automated response.
  'DangerousDoNotUseIntentionallyBreakThings',

  'ProductionGraphCompareMaterials',
  'ProductionGraphPurchaseOrdersDataset',

  // Temporary solution for Bupa contract
  'SupplyChainDisableLearningTasks',

  'SupplyChainDisableAutoMappingButton',
  'SupplyChainAutoMap10k',
  'MeasurementProjectDateEditingInAdmin',
  'SupplyChainManualTargets',
  'SupplyChainManualTargetsUpdatedDesign',
  'ShowAllLanguages',
  'UserUploadLimit',
  'SupplyChainExperimentalCache',
  'SupplyChainBulkImportContacts',
  'SupplyChainPaginateCompanies',
  'SupplyChainCleanPowerQuoteEnabled',
  'SupplyChainFilterIngestionToHighCdpDisclosureQuality',
  'SupplyChainShowHiddenCompaniesInIngestionSearch',
  'SupplyChainUseNewRefDataStructure',
  'SupplyChainHideNetworkTasksNavItem',
  'SupplyChainUseCacheArtifactV2',
  'SupplyChainUseSAndPDataFromParquet',

  'DataIngestionOnlyRegenerateStaleFacilitiesBarts',
  'DataIngestionSkipFacilitiesBartsAutoRefreshInFootprintGen',
  'SupplyChainEnableDefaultMappingCompanySearch',
  'DataIngestionDatasourceFilesRedesign',

  'BiV2DarkQuery',
  'BiV2Query',
  'BiV2FrontEndFormulation',
  'BiV2Metadata',

  'TransformRunWrite',
  'SupplyChainAutoMappingDuckDbPagination',
  'DataLineageFeedback',
  'EditFootprintAssemblyJsonConfig',
  'UseV2SyncPgToParquet',

  'BumpCleanPowerLimits',
  'UseDirectlyAssignedPermissions',
  'SupplyChainTargetsVsActual',
  'DustBotInProductPrototype',
  'CalculationMethodologyInProductPage',
  'ArchiveFootprints',
  'SupplyChainLogarithmicTargetReductionRate',

  'ProductionGraphUploadMaterialMaster',

  // Crudely track customers who are exempt from AI
  // until we have a more permanent solution
  // https://docs.google.com/spreadsheets/d/16KyBjCW-VtHflQLxFu5p7ofX6Qkqy_If/edit?gid=1818819503#gid=1818819503
  'CustomerExemptFromAI'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Flags = (typeof Flags)[keyof typeof Flags];

export const FlagStage = makeBetterEnum(
  /** Still being worked on by Eng */
  'Alpha',
  /** Safe to demo, safe to enable for select customers */
  'Beta',
  /** Launched features! Expectation is that these will be removed promptly */
  'GA',
  /** Useful for pricing and testing flags */
  'Permanent'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FlagStage = (typeof FlagStage)[keyof typeof FlagStage];

// Intended to give CSOL guidance about when to enable a flag and when not
// to enable a flag. Therefore, these should not be team-oriented, but
// feature / product oriented.
export const FeatureTag = makeBetterEnum(
  'CSRD',
  'SupplyChain',
  'OrgStructure',
  // testing and pricing are important flags to let CSOL know when to
  // not use them
  'Pricing',
  'Testing',
  // ... more?
  'ParityCheck',
  'Mapping',
  'Permissions'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FeatureTag = (typeof FeatureTag)[keyof typeof FeatureTag];

// NOTE: do not use User! This is an experimental feature by Enterprise Foundations
export const TargetType = makeBetterEnum('Organization', 'User');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TargetType = (typeof TargetType)[keyof typeof TargetType];

export type FeatureFlagMetadata = {
  description: string;
  team: Teams;
  stage: FlagStage;
  featureTags: Array<FeatureTag>;
  // NOTE: do not use User! This is an experimental feature by Enterprise Foundations
  targetType: TargetType;
};

// Makes the featureTags & targetType optional to simplify flag creation.
export type FeatureFlagMetadataDeclaration = Omit<
  FeatureFlagMetadata,
  'featureTags' | 'targetType'
> & {
  // Defaults to an empty array in FeatureFlagService.getStaticMetadata
  featureTags?: FeatureFlagMetadata['featureTags'];

  // NOTE: do not use User! This is an experimental feature by Enterprise
  // Foundations. Defaults to Organization in
  // FeatureFlagService.getStaticMetadata
  targetType?: FeatureFlagMetadata['targetType'];
};

export const featureFlagMetadata: Record<
  Flags,
  FeatureFlagMetadataDeclaration
> = {
  ApiUploadDataRateLimiter: {
    description: 'Enforce rate limits for API Uploads',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  ApiUploadDataRateLimiterLarge: {
    description: 'Enable a higher tier of rate limits for API Uploads',
    team: Teams.DataIngestion,
    stage: FlagStage.Permanent,
  },

  AssetManagerDisableSnapshotCreation: {
    description: 'Disable snapshot creation in Finance',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerDueDiligence: {
    description:
      'Asset Manager: Whether organizations have access to the due diligence tool  #team-win-finance',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerEstimationMethodologyVersioning: {
    description:
      'Asset Manager: Enable estimation methodology version selection for footprints',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerSidebarCanCollapse: {
    description:
      'Asset Manager: let you collapse fund categories in the sidebar  #team-win-finance',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  // TODO: remove this jawn
  AssetManagerConfigureDataQualityFlags: {
    description:
      'FY2025-Q1 Enabling customers to configure which flags should be processed in Asset Manager',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerScope2Subscopes: {
    description: 'FY2025-Q1 Enabling customers to see scope 2 subscope columns',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerYearOverYearComparison: {
    description:
      'Finance: Enabling customers to see year over year comparison page in finance sidebar',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerSkipFlagCreation: {
    description:
      'Finance: Used to skip populating data flags in snapshot creation.',
    team: Teams.WinFinance,
    stage: FlagStage.Permanent,
  },

  AssetManagerSkipLogEventCreation: {
    description: 'Finance: skip creating logEvents when uploading data',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerSnapshotDeletion: {
    description: 'Finance: Enable customers to delete snapshots.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerSkipOrgCheckDuringCompanyMatch: {
    description:
      'Finance: Skip org check during company match to enable company match for demo orgs',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerSkipSywFootprintSnapshot: {
    description:
      'Finance: Skip SYW during footprint creation to enable scalabillity for large orgs. SYW is currently hidden.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerViewEdciFieldsPreset: {
    description:
      'Finance: Used to gate access to EDCI fields that will be used reporting for Carlyle - should not be turned on for other orgs, as it depends on having a 1:1 asset <-> fund relationship (see notion documentation)',
    team: Teams.WinFinance,
    stage: FlagStage.Permanent,
  },

  AssetManagerGroupByCompanyName: {
    description:
      'Finance: Enables "Group by company name" in the finance overview',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerIncludeZeroFinancedEmissions: {
    description:
      'Finance: include rows associated with assets that have nonzero total emissions but zero financed emissions in the finance footprint snapshot.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
    featureTags: [],
    targetType: TargetType.Organization,
  },

  AssetManagerNegativeOutstandingAmount: {
    description: 'Finance: Enable negative outstanding amount in holdings',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerCurrencyDisplay: {
    description:
      'Finance: Updates UI to display currency-related metrics based on org currency preference.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerRenameFunds: {
    description:
      'Finance: Rename "Funds" to "Lines of business", plus categories, groups, etc.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerDataExplorerSyw: {
    description: 'Finance: Enable SYW for the finance data explorer.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerDataFlagsFromInputModels: {
    description:
      'Finance: generate data flags from input models rather than snapshot tables.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerPcafScore5ValueBasedEstimation: {
    description:
      'Finance: Enable PCAF score 5 value-based estimation. This should only be enabled for PCAF signatories.',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerMortgagesHoldingType: {
    description: 'Finance: enables mortgages as an asset class',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerProjectFinanceAssetClass: {
    description: 'Finance: enables project finance as an asset class',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerAlternateDataManagementDownload: {
    description:
      'Finance: when downloading the template from the data management page, use GCS rather than DataGrid',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerAlternateAssetYearDownload: {
    description:
      'Finance: when downloading the template from the data management page for asset years, use GCS rather than DataGrid',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerAlternateAssetCorporateDownload: {
    description:
      'Finance: when downloading the template from the data management page for corporate assets, use GCS rather than DataGrid',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerAutoMapping: {
    description:
      'Finance: enable auto mapping for assets in the finance product',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerUseSAndPData: {
    description: 'Finance: use S&P data for estimations',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerUseSAndPIndustryCodes: {
    description: 'Finance: use S&P industry codes for estimations',
    team: Teams.WinFinance,
    stage: FlagStage.Alpha,
  },

  AssetManagerDisableAuditReports: {
    description: 'Finance: disable audit reports in the finance product',
    team: Teams.WinFinance,
    stage: FlagStage.Alpha,
  },

  AssetManagerEnableAuditReports: {
    description:
      'Finance: enables audit reports in the finance product regardless of if they used S&P revenue data',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  AssetManagerUseBiV2ForSnapshotGrid: {
    description:
      'Finance: use BiV2 for the getSnapshotGrid endpoint. Extremely developmental, only should be used by engineers.',
    team: Teams.WinFinance,
    stage: FlagStage.Alpha,
  },

  AssetManagerSkipSAndPBQSync: {
    description:
      'Finance: skip the S&P data BQ sync during snapshot generation',
    team: Teams.WinFinance,
    stage: FlagStage.Alpha,
  },

  BenchmarkRevenueAtRisk: {
    description:
      'Expose the revenue at risk card in Benchmarks (FY2024-Q2)  #ask-supply-chain-and-company-data',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  BiDrilldownMultipleMeasures: {
    description:
      'HAS BUGS AND IS NOT STABLE ENOUGH TO USE. Allows multiple measure selection in the new BI drilldown view  #ask-datasets-n-objects',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  BiDrilldownFacilitiesMeasures: {
    description:
      'Shows the facilities measures in the BI drilldown view #ask-datasets-n-objects',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  BiDrilldownSupplierMeasures: {
    description:
      'Shows the supplier measures in the BI drilldown view #ask-datasets-n-objects',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },

  BigRocksUiFinance: {
    description:
      'Reskin Finance product with new UI design. #proj-big-rocks-ui-foundations',
    team: Teams.WinFinance,
    stage: FlagStage.Beta,
  },

  EnablePreSetCustomTagsForBuildings: {
    description:
      'Enable data collection tasks _for facilities_ to have pre-set custom tag values. These are derived from custom tags added to buildings. #proj-org-structure',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  DashboardCleanPowerAdvancedEstimation: {
    description:
      'Dashboard includes clean power module (2022-Q4). #team-sharing',
    team: Teams.SupplyChain,
    // only enabled for demo orgs so should probably be removed?
    stage: FlagStage.Alpha,
  },

  DemoPlatformEnableForRestrictedOrg: {
    description:
      'If enabled, allows demo platform to be run manually for restricted orgs',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.GA,
  },

  DisableContractorAccess: {
    description:
      'Prevent SalesforcePollingService from giving access to contractors for this org',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  DisableSalesforceSyncSupportPermissions: {
    description:
      'Disable SalesforcePollingService from changing any user access for this org',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Permanent,
  },

  EntFoundAccessSecuritySettings: {
    description: 'Display the new access and security settings page',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },

  EntFoundTempApiSecretCompareToHash: {
    description:
      'Temporary flag to start comparing API secrets to their hashes instead of them directly in the DB',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },

  EntFoundDirectorySync: {
    description: 'Display link to directory sync enablement',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  EntFoundDirectorySyncWriteChanges: {
    description:
      'Run WorkOS Directory Sync *without* dry run mode (opting into writing)',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  EntFoundIdleLogout: {
    description: 'Enable idle logout for orgs',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  EntFoundSkilljarLinks: {
    description: 'Display link to onboarding (using Skilljar)',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  EntFoundEmailRestrictions: {
    description:
      'This is a long-lived contractual commitment flag to turn off click handling and modify how links are displayed in product and emails. It was originally created for Morgan Stanley. See ENT-1848',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },

  EntFoundSettingsV2: {
    description: 'Display new settings page for Enterprise Foundations',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },

  EntFoundDisableRouteAccessRequestsToOpal: {
    description:
      'Disable routing admin org access requests to Opal and use internal auto-approval instead as an escape hatch when Opal is down',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Permanent,
    featureTags: [],
    targetType: TargetType.Organization,
  },

  EntFoundGateAdminAccess: {
    description:
      'Enable all of the restricted admin access features for this user',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
    featureTags: [],
    targetType: TargetType.User,
  },

  FootprintSuppliersPage: {
    description: 'Display Suppliers page under Footprint #team-supply-chain',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  SupplierExpenseCategories: {
    description:
      'Enable showing and surveying expense categories for suppliers, aka DiscloseEmissionsV2',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  SupplierEngagementSurveys: {
    description:
      'Paid supplier engagement add-ons, including supplier KPI metrics, pre-loaded data about supplier emissions, supplier engagement surveys, supplier maturity scoring, and custom columns) #team-supply-chain',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  SupplierFootprintUncollapse: {
    description:
      'If true, use uncollapsed footprint fields on the suppliers page. This is potentially expensive; ask before enabling! #team-supply-chain',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  SupplyChainChartsConfigAdvancedControls: {
    description:
      'Enable supply chain advanced configs in product. #team-supply-chain',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  SupplyChainHideArchivedConfigsFromCharts: {
    description: 'Hide the archived engagement task configs from charts',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
  },

  MeasurementIntegrationsQuickBooks: {
    description: 'Measurement Integrations (2022-C1) #team-measurement-product',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  MeasurementIntegrationsUrjanet: {
    description: 'Measurement Integrations (2022-C1) #team-measurement-product',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  MeasurementIntegrationsArcadia: {
    description: 'Measurement Integrations (2022-C1) #team-measurement-product',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  MeasurementIntegrationsNetSuite: {
    description: 'Measurement Integrations (2022-C1) #team-measurement-product',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  MeasurementIntegrationsMergeFinancials: {
    description: 'Measurement Integrations (2022-C1) #team-measurement-product',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  FootprintDisableWriteInputQuantities: {
    description:
      'Disable writing input quantities during footprint gen, this will disable product-level measurements from being uploaded which power the "Products and materials" tab when `FootprintProductLevelEmissions` is enabled',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  FootprintProductLevelEmissions: {
    description:
      'Describes whether product level measurements, powered by emissions models, are enabled. If this is enabled, then the user will have a new tab for "Products and materials" in their Footprint, and the ability to see lifecycle maps for either products or materials. https://www.notion.so/watershedclimate/Emissions-models-7754b2fd2f474f4caaa940a1b8882d6b',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  FootprintShowProductModels: {
    description:
      "If `FootprintProductLevelEmissions` is on, this flag controls which experience the user sees - if it's on, the user will see emissions models for their products, whereas if it's off, the user will see emissions models for their materials instead.",
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  AlwaysAllowTcfdQualitative: {
    description:
      'Org-level override to allow qualitative TCFD sections no matter what plan they are on. This is to abide by contractual obligations we have with certain organizations.',
    team: Teams.Reporting,
    stage: FlagStage.Beta,
  },

  ReportingUseBiV2: {
    description: '2024-Q4: Use BI V2 for reporting BiQuery questions',
    team: Teams.Reporting,
    stage: FlagStage.GA,
  },

  ReportingShowRegulatoryCheckup: {
    description:
      'Flag to disable regulatory reporting check-up section on disclosures and reports page.',
    team: Teams.Reporting,
    stage: FlagStage.Beta,
  },

  // Note: this flag is not explicitly referenced in any code, but it is being implicitly used by the CDP report config.
  ReportingIgnoreCdpPlanRestrictions: {
    description:
      'Ignore the ordinary CDP plan restrictions for a particular customer.',
    team: Teams.Reporting,
    stage: FlagStage.Beta,
  },

  ReportingCdpAlwaysOverwriteConflicts: {
    description:
      'Rather than showing a warning when syncing to CDP, just always sync. Helpful in debugging, not something that we would want to enable for all orgs.',
    team: Teams.Reporting,
    stage: FlagStage.Alpha,
  },

  ReportingUseTitleSubtitleMigratedColumns: {
    description:
      'Use the migrated title and subtitle columns on ReportQuestion table rather than the title and subtitle fields from input object',
    team: Teams.Reporting,
    stage: FlagStage.Alpha,
  },

  ReportingFreezeConfigEdits: {
    description: 'Freeze Reporting config edits.',
    team: Teams.Reporting,
    stage: FlagStage.Permanent,
  },

  EmissionsModelMinimizeVariableNameInSql: {
    description: 'Minimize variable name in the SQL generated from lscript.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  EnablePublishFileUploadFacilities: {
    description:
      '2023-Q2: Enable publishing file upload facilities AutoBARTs. Without this flag, file uploads are only used to push back into the buildings survey (which will ultimately generate an AutoBART with all buildings in it).',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  ReduxSupplierEngagementBySpend: {
    description:
      '2023-Q2: Enables supplier engagement by spend targets in redux #team-reduction-targets',
    team: Teams.Reductions,
    stage: FlagStage.Beta,
  },

  ReduxDisableCustomTagsInContributions: {
    description:
      '2024-Q1: Disables custom tags from returning in the footprint for large customers #team-europe-epd',
    team: Teams.Reductions,
    stage: FlagStage.Beta,
  },

  ReduxExperimentalCalculations: {
    description:
      '2023-Q3: Enable experimental calculation rewrite modes #team-reduction-targets',
    team: Teams.Reductions,
    stage: FlagStage.Beta,
  },
  ReduxContributionsIntervalOptimization: {
    description:
      '2024-Q2: Fetch fewer contributions for non-reference year intervals.',
    team: Teams.Reductions,
    stage: FlagStage.Beta,
  },
  ReduxUseLocationBasedEmissions: {
    description: 'FY25-Q3: Use location-based emissions in reduction plans',
    team: Teams.Reductions,
    stage: FlagStage.Beta,
  },
  TempIndividualWritesForMappings: {
    description:
      '2023-Q1: Temp hack to write mappings individually rather than in bulk',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  MultipleDatasourcesPerAdt: {
    description:
      '2023-Q1: In the ADT create/edit dialogs, whether to allow choosing multiple datasources per ADT. All backend logic is migrated to the new ActivityDataTableDatasource model for this, but this lets us slow roll the availability to CDAs.',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  DisableDataGovernancePage: {
    description:
      '2023-Q1: The data governance page shows a customer data with provenance information. This flag disables it.  #ask-data-ingestion',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  ActivityDataAllActivityButton: {
    description:
      '2024-Q2: NOT READY FOR CUSTOMERS! Whether to show the "View all activity data" button. #ask-datasets-n-objects',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Beta,
  },

  ActivityDataNumericCustomColumns: {
    description:
      '2024-Q4: NOT READY FOR CUSTOMERS! Allows users to aggregate numeric custom columns from activity data in BI',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },

  DataIngestionDataAuditTransformation: {
    description: '2023-Q2: New page for data audits',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  Unknown: {
    description:
      'A catch-all id for unknown feature flags. Used as a backstop in our coercion code.',
    team: Teams.Engineering,
    stage: FlagStage.Beta,
  },

  ShouldAllowPipelineResultFootprintKind: {
    description:
      '2023-Q1: Allow adding a footprint_kind to a pipeline result. #ask-calcprint',
    team: Teams.Calcprint,
    stage: FlagStage.Permanent,
  },

  FootprintBenchmarksForNonCorporate: {
    description:
      '2023-Q2 If enabled, show benchmarks on the footprint overview page for non-corporate footprints.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission: {
    description:
      '2023-Q4 If enabled, shows the new data ingestion value mapping experience (for Financials datasets only, ex. Capital Expenses), and allows users to edit value mapping. This is the full experience for Financials value mapping. #proj-value-mapping',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.Mapping],
  },

  DataIngestionValueMappingsVendorRowsExpanded: {
    description:
      '2024-Q1 If enabled, enables value mappings with vendors to show additional vendors per account.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.Mapping],
  },

  DataIngestionValueMappingDisableVendorPreviews: {
    description:
      '2023-Q4 If enabled, stops showing vendor previews (for Financials datasets only, ex. Capital Expenses) in product.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.Mapping],
  },

  DataIngestionDisableExportFacilitiesDocs: {
    description:
      '2023-Q4 If enabled, removes the ability for users to export their facilities supporting docs',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  // TODO: remove this jawn
  DataIngestionColocatedDataCentersInFacilities: {
    description:
      'FY25Q1 If enabled will support colocated data centers with electricity data in facilities',
    team: Teams.DataIngestion,
    stage: FlagStage.GA,
  },

  // TODO: remove this jawn
  DataIngestionEditablePowerUsageEffectiveness: {
    description:
      'TY21Q1 If enabled, colocated datacenter PUE will be editable in the facilities buildings UI',
    team: Teams.DataIngestion,
    stage: FlagStage.GA,
  },

  DataIngestionTestCts: {
    description:
      'Provides access to a super secret, hardly even real test CTS to be used to migrate to Data Registry',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  DataIngestionFilefeeds: {
    description: 'Enable filefeeds for data cleaning pipelines',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  DataIngestionDisableValueMappingTaskAssignment: {
    description:
      'Turns off an expensive code path in the active measurement page to mitigate #incident-may-14-2024-jpmc-active-measurement-page-latency-regression',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.Mapping],
  },

  DataIngestionFacilitiesTracker: {
    description:
      '2024-Q2 A new facilities tracker page for better project management capabilities',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },

  DataIngestionFacilitiesExtraLargeMode: {
    description:
      '2024-Q2 If enabled, pare down facilities functionality so that the product works for large buildings and utilities uploads',
    team: Teams.DataIngestion,
    stage: FlagStage.Permanent,
  },

  DataIngestionFacilitiesUseLoader: {
    description:
      '2025-Q1 If enabled, use the optimized loading for datasource page. If perf issues worsen, disable.',
    team: Teams.DataIngestion,
    stage: FlagStage.Permanent,
  },

  DataIngestionAllowUploadsOnActivityDataPage: {
    description:
      '2024-Q2 If enabled, allows users to upload files on the activity data page',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },

  DataIngestionSkipSendingApprovalFlowEmails: {
    description:
      '2025-Q1 If enabled, skips sending approval flow emails in tasks.',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
    featureTags: [],
    targetType: TargetType.Organization,
  },

  DataIngestionImporterV3: {
    description: '2025-Q1 If enabled, use the new importer v3',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
    featureTags: [],
    targetType: TargetType.Organization,
  },

  DataIngestionUpgradeImporterV1: {
    description: '2025-Q1 If enabled, ImporterV1 will upgrade to ImporterV3',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
    featureTags: [],
    targetType: TargetType.Organization,
  },

  DataIngestionFacilitiesApprovals: {
    description:
      '2024-Q2 Facilities redesign to support approval workflows. NOTE: Turning on this feature flag requires a corresponding data migration to be run. You may encounter errors if you enable it without running the migration. Ask the Data Ingestion team if you need help.',
    team: Teams.DataIngestion,
    stage: FlagStage.GA,
  },

  DataIngestionDirectEntry: {
    description: '2024-Q3 Enables direct entry of activity data in upload flow',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },

  DataIngestionApiDataRegistry: {
    description: '2024-Q2 Use Data Registry for API Upload data',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  DataIngestionGlobalFacilitiesPage: {
    description: '2024-Q4 Allow access to global facilities page',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },

  DisableFinancialMappingAiSuggestions: {
    description:
      '2024-Q2 #team-calculation Disables suggestions based on large language models. These customers have opted out of AI suggestions',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.Mapping],
  },

  DisableFootprintGeneration: {
    description:
      '2023-Q3 Disables footprint generation in product. #ask-calcprint',
    team: Teams.Calcprint,
    stage: FlagStage.Permanent,
  },

  UseDedicatedTaskQueueForTraceAddendum: {
    description:
      '2024-Q1 Use dedicated task queue for trace addendum #incident-feb-1-2024-pipeline-scaling-troubles',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  DataInfraMaterializeTableInTraceAddendum: {
    description:
      '2024-Q4 Materialize the table in the trace addendum. https://www.notion.so/watershedclimate/Trace-addendum-computation-131e2b76a3fb802b9bb7e5b34845ff8a',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Alpha,
  },

  LogAuditEventsToWorkOs: {
    description:
      '2023-Q2 Log audit events to workos #ask-enterprise-readiness #security #team-enterprise-foundations',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },

  ShouldUseTestDuckDbServer: {
    description:
      '2023-Q3 Use duckdb-server-test for all duckdb-server requests.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Permanent,
  },

  ShouldUseTestTemporalTaskQueue: {
    description: 'Use test task queue for Temporal workflows.',
    team: Teams.Infrastructure,
    stage: FlagStage.Permanent,
  },

  BulkUploadTaskManagement: {
    description:
      '2023-Q3 Show a dialog to create and reassign upload tasks. #ask-data-ingestion',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  ContractOverrideUpsellBenchmarks: {
    description:
      'Contract override upsell for Benchmarks feature. Ideally to be replaced in FY24-Q3 with a permanent solution pulling from SFDC. See #proj-watershed-plan https://watershedclimate.slack.com/archives/C03GUFDA4MC/p1691506636142849?thread_ts=1686153981.781159&cid=C03GUFDA4MC',
    team: Teams.Engineering,
    stage: FlagStage.Beta,
  },

  SupplyChainGeneralMillsCompanyEngagementEmail: {
    description:
      "2023-Q3 General Mills' custom email template for company engagement. #ask-supply-chain",
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  CompleteFiscalYearInOverview: {
    description:
      "2023-Q3 Enables showing trailing 12m intervals in the Overview page according to the org's fiscal year. Requested by Jefferies.  #ask-calcprint",
    team: Teams.Calcprint,
    stage: FlagStage.Permanent,
  },

  OverrideBlockedPipelineV2Dialog: {
    description:
      '2023-Q3 Flag to allow CDAs to use Pipeline V2 for an org even if the org has not used pipeline v2 previously. #ask-calculation',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  DefaultDrilldownChartsClosed: {
    description:
      '2023-Q3 Feature flag to default not open / load drilldown charts #ask-supply-chain',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  CalculationMethodologyCustomizationPage: {
    description:
      '2023-Q3 Feature flag to show methodology/EF customization page #ask-calculation #proj-methodology-customization',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  CalculationMethodologyInProductPage: {
    description:
      '2025-Q1 Feature flag to show WIP methodology-in-product page in product #ask-calculation #proj-methodology-in-product',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },

  DataIngestionSnowfall: {
    description:
      '2023-Q3 Data Ingestion Hack day for Reset Week Adds delightful snowfall experience to the datasource pages #team-data-ingestion',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  CalculationKeepZerosInPipelineResults: {
    description:
      '2023-Q4 When enabled zero input quantity is never filtered from pipeline result creation from emissions model evaluations.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  CalculationEnableAlphaReleases: {
    description:
      '2024-Q2 Enables alpha releases to be selected as part of the footprint config flow in product.',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },

  CalculationEnablePipelinesPrototype: {
    description:
      '2024-Q2 #explore-pipelines Enables pipelines prototype (ref data, emissions models, etc) in product.',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },

  ProductRulesContainsOperator: {
    description:
      'FY 2026 Q1 #proj-contains-operator Enables using contains operator in exclusion and recat',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },

  ConsumerGoodsApparelNewMethodologies: {
    description:
      '2023-Q4 Enables beta chips, callouts, and (if applicable) data upload disable for CG/Apparel datasets in beta status. #team-methodology',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  ActivityDataMultipleMeasures: {
    description:
      'HAS BUGS AND IS NOT STABLE ENOUGH TO USE. 2023-Q4 Allow multiple measures in activity data views. #proj-activity-data',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },

  DisableFacilitiesGeocoding: {
    description:
      'Acts as an override to disable geocoding of facilities building addresses, which is ordinarily enabled by PricingFy25CsrdE3ModuleTemp. #team-win-csrd',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  ShouldUseExtraLargeDuckDbServer: {
    description:
      'Deprecated. Use Resource Estimation. FY2024-Q4 to add an extra large duckdb server. #duckdb',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Permanent,
  },

  BumpCleanPowerLimits: {
    description: '2025-Q1 Bumps limits for clean power allocation.',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
    featureTags: [],
    targetType: TargetType.Organization,
  },

  TurnOffCdpUpdateFeatureOnboardingDialog: {
    description:
      'FY2024-Q4 Failsafe to urn off CDP update feature onboarding dialog in case dialog goes wonky',
    team: Teams.Reporting,
    stage: FlagStage.Permanent,
  },

  CsrdEnableAiExtractedAnswers: {
    description: 'FY2025-Q3 Enables AI extracted answers in CSRD report',
    team: Teams.WinCsrd,
    stage: FlagStage.Alpha,
  },

  CsrdEnableAiNarrativeExport: {
    description: 'FY2026-Q1 Enables AI narrative export in CSRD report',
    team: Teams.WinCsrd,
    stage: FlagStage.Alpha,
  },

  EnableFacilitiesWaterForGhg: {
    description:
      'FY2025-Q1 Enables entry of water data via the utilities modal and IDI templates. #proj-water-waste',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },

  EnableFacilitiesWasteForGhg: {
    description:
      'FY2025-Q1 Enables entry of waste data via the utilities modal and IDI templates. #proj-water-waste',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },

  SupplyChainCustomSupplierConfig: {
    description: 'FY2025-Q1 Enables custom supplier config UI',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  TransformRunWrite: {
    description:
      'FY2025-Q4 write transform run records during footprint generation',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  LimitLargeGroupDataLineage: {
    description:
      'FY2025-Q1 By default, data lineage is available for all rows in drilldown. After some optimization work we have tested and believe that this will scale to all customers, but this feature flag is an escape hatch in case we do run into scale issues again. #ask-calcprint',
    team: Teams.Calcprint,
    stage: FlagStage.GA,
  },

  ByPassChonkerChecker: {
    description: 'By passes ChonkerChecker file size checks. #ask-calculation',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  CsrdEnableReportQuestionApprovals: {
    description: 'FY2026-Q1 Enables approvals for report questions',
    team: Teams.WinCsrd,
    stage: FlagStage.Alpha,
  },

  SupplyChainReopenApprovedTask: {
    description:
      'Enables re-opening approved tasks. Full context here: https://watershedclimate.slack.com/archives/C03A2JW2RS6/p1711469873204239 #ask-supply-chain-and-company-data',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },

  EnableUserUploadCountsOnMeasurementPage: {
    description:
      'Displays count of uploaded files against tasks on the measurement page',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },

  // TODO: remove this jawn
  DataInfraCreateTempTableInCleanPower: {
    description:
      'Test DuckDB memory benefits of creating a temporary table before writing to parquet during clean power portion of footprint assembly.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.GA,
  },
  DataInfraBumpMaxExpressionDepthFootAssembly: {
    description:
      'Bumps the max expression depth for footprint assembly queries to 2000 when there are too many pipeline results.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Beta,
  },
  DataInfraUseDuckDbResourceEstimation: {
    description:
      'For use while rolling out new Resource Estimation in DuckDB. Using to ensure we safely move traffic between servers.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.GA,
  },
  DataInfraOnlyUseDuckDbTestExtraLargeServer: {
    description:
      'Do not use unless you are on Data Infra. This moves traffic to an XL test server for DuckDB for use in load testing.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Alpha,
  },
  DataInfraOnlyUseDuckDbTestServersForResourceEstimation: {
    description:
      'Do not use unless you are on Data Infra. For Resource Estimation load test. Uses both XL and regular test DuckDb instances.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Alpha,
  },
  DataInfraUseLscriptDateCastOptimization: {
    description:
      'For use while rolling out new Lscript date cast optimization in DuckDB. Using flag so we can safely roll back if needed.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Alpha,
  },
  DataInfraUseLscriptReferenceFilterOptimization: {
    description:
      'For use while rolling out new Lscript Reference Data Filter Optimization. Using flag so we can safely roll back if needed.',
    team: Teams.DataInfrastructure,
    stage: FlagStage.Alpha,
  },
  RebuildValueMappingContextOnDuckDbExtraLarge: {
    description:
      'For some customers rebuilding value mapping context requires extra large DuckDB server. Will be removed once they use resource estimation instead.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  LocalizationDynamicStrings: {
    description: 'Load translations for dynamic strings',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  EnableEditProductRulesAlways: {
    description:
      'Temporary flag allowing to edit product rules (recat and dedup) even if there is no draft footprint or active measurement project.',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },
  OrgStructure: {
    description:
      'FY2025-Q2  #team-enterprise-foundations Enables all org structure capabilities.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.OrgStructure],
  },
  OrgStructureMultipleVersions: {
    description:
      'FY2025-Q3  #team-enterprise-foundations Enables the multiple versions feature in org structure capabilities.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.OrgStructure],
  },
  OrgStructureChangelog: {
    description:
      'FY2025-Q2  #team-enterprise-foundations Enables the changelog for org structure in the sidebar.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.OrgStructure],
  },
  OrgStructureAutofillingEnabled: {
    description:
      'FY2025-Q2  #team-enterprise-foundations Enables the autofilling feature of org structure, should only be used if an org has a complete org structure.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
    featureTags: [FeatureTag.OrgStructure],
  },
  UseBartForHeadcountIntensity: {
    description:
      'FY2025-Q2  #ask-datasets-n-objects If enabled we will use direct employee activity data for calculating headcount intensity throughout the product instead of the intensity denominator data.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  CsrdAvoidedCleanPower: {
    description:
      'FY2025-Q2 #proj-e1-quant Calculate avoided emissions and track clean power contractual instrument from facilities through to reporting.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  ReportingTextInputRichText: {
    description:
      'FY2025-Q2 #team-win-csrd Enables rich text input for reporting text fields.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  ReportingEnableNewClimateIntelligencePanel: {
    description:
      'FY2025-Q2 #team-win-csrd Enables the new climate intelligence panel.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  ReportingEnableCdpApi: {
    description: 'FY2025-Q2 Enables CDP API sync',
    team: Teams.Reporting,
    stage: FlagStage.Beta,
  },
  ReportingEnableCdpApiUnscoredDeadline: {
    description: 'Enable CDP sync past scoring deadline (14 October 2024)',
    team: Teams.Reporting,
    stage: FlagStage.Beta,
  },
  // i know we marked this as Permanent, but it looks like its flipped on for all orgs...
  // TODO: remove this jawn
  ReportingShowCdpApiIsDownWarnings: {
    description:
      'FY2025-Q3 - Enables warnings in the report-editor when CDP inevitably goes down during CDP-submission-time',
    team: Teams.Reporting,
    stage: FlagStage.Permanent,
  },
  ReportAnswerPermissions: {
    description:
      'FY2025-Q4 #team-reporting Enables granting of permissions to view and answer specific questions for reports',
    team: Teams.Reporting,
    stage: FlagStage.Alpha,
  },
  ReportPermissionManagement: {
    description:
      'FY2026-Q1 #team-reporting Enables the report-level management of permissions',
    team: Teams.Reporting,
    stage: FlagStage.Alpha,
  },
  SendEmailsOnDemoOrTestOrg: {
    description:
      'FY2025-Q2 #team-win-csrd Actions that trigger emails will trigger emails, even on demo or test orgs.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  DataIngestionCsrdApprovalFlow: {
    description:
      'FY2025-Q2 #team-data-ingestion Enables more audit-ready approval flow for data ingestion.',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },
  SupplyChainAdminEditCompany: {
    description:
      'We block direct creation and modification of companies in admin so that our company data remains clean. All company creation should go through ingestion. This should only be flipped on by the supply chain team for certain exceptions and then flipped off once the company is added.',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
  },
  SupplyChainEmissionsExport: {
    description:
      'NOTE: THIS SHOULD NOT BE TURNED ON FOR ANY CUSTOMER. This is intended as a Watershed-internal tool to view emissions YoY for suppliers, to be then aggregated for customers. This export should never be shared with a customer - we should only share emissions at an aggregate level, as per CDP contract.',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  BiDrilldownAiQuerying: {
    description:
      'FY2025-Q2 #proj-ai-querying-drilldown [DOES NOT WORK RIGHT NOW] Enables natural language querying in drilldown',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  CsrdDataRequirementsValidation: {
    description:
      'FY2025-Q4 Runs the Data Requirements validation on file uploads',
    team: Teams.WinCsrd,
    stage: FlagStage.Alpha,
  },
  LearningHubReplaceUrls: {
    description:
      'Experimental feature to replace LearningHub URLs with our own - allowing customers to open LearningHub content in a new tab successfully',
    team: Teams.DataIngestion, // NOTE (bryan): Which team?
    stage: FlagStage.Alpha,
  },

  /**
   * BEGIN FY25 Temp pricing flags
   */
  PricingFy25ReportingModuleTemp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations Flag to enable the Reporting module for organizations that are not on a Watershed plan.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope1And2Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scopes 1+2 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockAllScope3Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_1Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.1 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_2Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.2 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_3Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.3 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_4Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.4 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_5Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.5 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_6Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.6 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_7Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.7 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_8Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.8 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_9Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.9 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_10Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.10 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_11Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.11 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_12Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.12 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_13Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.13 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25BlockScope3_14Temp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations If enabled, the user will NOT see any emissions associated with Scope 3.14 in their footprint.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25ReductionsModuleTemp: {
    description:
      'FY2025-Q2 #team-enterprise-foundations Used to price-gate the reductions module. If enabled, the reductions module will be accessible.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdE1ModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD E21 module. This flag does not control the enablement of measurement, but it does control features that must accompany E1. For example, net revenue uploads and carbon credit retirement views in drilldown.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdE2ModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD E2 module. If enabled, measurement and calculation of E2 metrics will become accessible.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdE3ModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD E3 module. If enabled, measurement and calculation of E3 metrics will become accessible.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdE4ModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD E4 module. If enabled, measurement and calculation of E4 metrics will become accessible.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdE5ModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD E5 module. If enabled, measurement and calculation of E5 metrics will become accessible.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdSocialAndGovernanceModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD S&G module. If enabled, measurement and calculation of S&G metrics will become accessible.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  PricingFy25CsrdReportBuilderModuleTemp: {
    description:
      'FY2025-Q3 #team-win-csrd Used to price-gate the CSRD report builder module. If enabled, users will have access to the CSRD report builder.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  /**
   * END FY25 Pricing Flags
   */
  UseBartForMonetaryIntensity: {
    description:
      'FY2025-Q2 #proj-custom-metrics If enabled, use the intensity_denominator_monetary BART to power monetary intensities',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  UseBartForCustomIntensity: {
    description:
      'FY2025-Q2 #proj-custom-metrics If enabled, use the intensity_denominator_custom BART to power custom intensities',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  EnableGeneralAnomalyDetection: {
    description:
      'FY2025-Q2 #ask-datasets-n-objects If enabled, show anomaly detection on activity datasets and drilldown.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  SupplyChainTargetsInitiativesExport: {
    description:
      'FY2025-Q2 If enabled, gives option in dashboard to export Supplier emissions, targets, and initiatives',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },
  SupplyChainBulkSurveyResponseExport: {
    description:
      'FY2025-Q3 If enabled, gives option in Suppliers Tasks page to export survey answers',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  ShowTopSuppliersTableInFootprintOverview: {
    description:
      'FY2025-Q2 #team-supply-chain SUP-6847: If enabled, show the suppliers table in the footprint overview tab.',
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },
  EnableUtilityFileScanning: {
    description:
      'FY2025-Q2 #proj-ocr: If enabled, allow users to scan and parse their utility bills with OCR.',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },
  ActivityDataListShowAllSchemas: {
    description:
      'FY2025-Q2 #ask-datasets-n-objects: A FF for developers to see schemas even if there is no data for them.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  ShowYourWorkTotalRow: {
    description:
      'FY2025-Q2 #team-win-csrd: If enabled, show the "Show Your Work" button on Total rows in Drilldown.',
    team: Teams.WinCsrd,
    stage: FlagStage.Beta,
  },
  CalculationEmEvalRefactorParity: {
    description:
      'Dual write PLRs using the new EM eval code, and do a parity check.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },
  CalculationEmEvalRefactorCut: {
    description: 'Only write PLRs using the new EM eval code.',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },
  AutoRunFootprintOnUserUpload: {
    description:
      'Run a new footprint automatically each time a user upload is created',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },
  DataIngestionUserUploadEdit: {
    description: 'Enable users to edit user uploaded files',
    team: Teams.DataIngestion,
    stage: FlagStage.Beta,
  },
  DataIngestionDatasourceFilesRedesign: {
    description:
      'Datasource page has the new version of files list including pagination',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },
  DataIngestionDisableOneSchemaAi: {
    description:
      'Disable OneSchema feature that allows uploads to receive AI-based suggestions',
    team: Teams.DataIngestion,
    stage: FlagStage.Permanent,
  },
  DataLineageV2: {
    description: 'Enable data lineage v2, which uses Transforms',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },
  IgnoreFootprintRowLimit: {
    description:
      'Disable row limit enforcement for footprints [FOR TESTING ONLY, DO NOT USE FOR CUSTOMERS]',
    team: Teams.Calcprint,
    stage: FlagStage.Permanent,
  },
  FootprintAuditBundleRowLimit: {
    description:
      'FY2025-Q4 #ask-calcprint: Enforce a row limit on footprint audit bundles',
    team: Teams.Calcprint,
    stage: FlagStage.Beta,
  },

  DangerousDoNotUseIntentionallyBreakThings: {
    description:
      'NOT FOR PUBLIC CONSUMPTION! This flag is used to intentionally break parts of the product and test our automated responses.',
    team: Teams.Engineering,
    stage: FlagStage.Beta,
  },
  EnableCompanyMergingAdmin: {
    description: 'Enable company merging in admin (must be flipped globally)',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
  },
  SupplyChainDisableLearningTasks: {
    description:
      "Disable learning engagement tasks from showing up for users since they won't internationalized soon",
    team: Teams.SupplyChain,
    stage: FlagStage.Beta,
  },
  SupplyChainDisableAutoMappingButton: {
    description: 'Disable the automapping button on the supplier mapping page',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
    featureTags: [FeatureTag.Mapping],
  },
  SupplyChainAutoMap10k: {
    description:
      'DO NOT USE WITHOUT PERMISSION FROM EPDS. Allow mapping up to 10k of suppliers by spend (pre-measurement) or emissions (post-measurement). Built for customer escalations only. Reach out to #ask-supply-chain-epds',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
    featureTags: [FeatureTag.Mapping],
  },
  MeasurementProjectDateEditingInAdmin: {
    // TODO(2025-03-01): Remove this flag and admin measurement editing entirely once confirmed we don't need this.
    description:
      'Allow editing measurement project dates in admin. We intend to deprecate this feature and this flag is a stopgap just to see whether we still have the need. Only the Data Ingestion team should enable this; if you are blocked by not having this ability, please submit a #submit-epds-product-issues ticket.',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },
  SupplyChainManualTargets: {
    description:
      'Flag to enable creating, editing and deleting manual entry targets in dashboard. This FF is not intended to be enabled for any org long term.',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  ShowAllLanguages: {
    description:
      'Localization: show all languages even the ones that are in progress and not launched to customers yet.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Permanent,
  },
  BiEnableMetricDescriptions: {
    description:
      'Show descriptions for metrics in BI. Behind a FF because we have not audited all of the existing descriptions',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  UserUploadLimit: {
    description:
      'Limit the number of user uploads that get returned as part of the user upload task to prevent the page from crashing. Ideally a temporary fix until we paginate this query.',
    team: Teams.DataIngestion,
    stage: FlagStage.Alpha,
  },
  ProductionGraphCompareMaterials: {
    description:
      'Enables a new page for sustainable procurement leads to compare materials',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  ProductionGraphPurchaseOrdersDataset: {
    description: 'Enable the purchase orders dataset for an organization',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  SupplyChainExperimentalCache: {
    description:
      'FY2026-Q1 Enable an experimental cache that avoids recomputing footprint aggregations for supplier details page',
    team: Teams.SupplyChain,
    stage: FlagStage.GA,
  },
  SupplyChainBulkImportContacts: {
    description:
      'Flag to enable option in Suppliers Tasks page to bulk import contacts',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  SupplyChainPaginateCompanies: {
    description: 'Flag to enable company pagination for benchmarks safely.',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  FootprintGenerationAllowPreEmissionsModelGaActivityData: {
    description:
      'Allow pre-emissions model GA activity data to be used in footprint generation',
    team: Teams.Calcprint,
    stage: FlagStage.Permanent,
  },
  DataIngestionOnlyRegenerateStaleFacilitiesBarts: {
    description: 'Only regenerate stale facilities BARTs for data ingestion',
    team: Teams.DataIngestion,
    stage: FlagStage.GA,
  },
  DataIngestionSkipFacilitiesBartsAutoRefreshInFootprintGen: {
    description:
      'Skip auto-refreshing facilities BARTs in footprint generation, instead only refresh the BARTs that are stale',
    team: Teams.DataIngestion,
    stage: FlagStage.GA,
  },
  SupplyChainEnableDefaultMappingCompanySearch: {
    description:
      'Enable running the company search for the supplier mapping page by default',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
    featureTags: [FeatureTag.Mapping],
  },
  SupplyChainCleanPowerQuoteEnabled: {
    description:
      'Enable the option to get a clean power quote from the marketplace page. This is a temporary flag as we fully deprecate this feature.',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  SupplyChainFilterIngestionToHighCdpDisclosureQuality: {
    description:
      'Ingestion queue only shows diffs corresponding to CDP vendor data of high or very high disclosure quality score for the most recent publishing year. This ensures company ingestion focuses on the rows we will create EFs for.',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
  },
  SupplyChainShowHiddenCompaniesInIngestionSearch: {
    description:
      'Ingestion queue does not allow matching on hidden companies. This generally makes sense but we might need one-off instances where we do match to a hidden company — such as to unblock CDP EF generation.',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
  },
  SupplyChainHideNetworkTasksNavItem: {
    description: 'Hide the Network Tasks nav item',
    team: Teams.SupplyChain,
    stage: FlagStage.Permanent,
  },
  SupplyChainUseNewRefDataStructure: {
    description:
      "As part of CDP 2024 ingestion, we'll be modifying the ref data. Now, we need to reference expenditure_override and expenditure_cdp to identify if a company ID has an associated supplier specific EF. After we cut the new CMV, we'll turn on this FF.",
    team: Teams.SupplyChain,
    stage: FlagStage.GA,
  },
  SupplyChainUseCacheArtifactV2: {
    description: 'Use new cache artifact for supply chain data',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  SupplyChainUseSAndPDataFromParquet: {
    description: 'Use S&P data from parquet files rather than postgres',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  BiV2DarkQuery: {
    description:
      'When querying BI v1, also query BI v2 in the background and compare the results. This is a temporary flag to test for parity between BI v1 and BI v2.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Beta,
  },
  BiV2Query: {
    description: 'Run BI queries against the BI V2 service',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.GA,
  },
  BiV2FrontEndFormulation: {
    description:
      'Run query formulation logic for BI V2 in the frontend rather than in the service. Depends on BiV2Query.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.GA,
  },
  BiV2Metadata: {
    description: 'Use table/schema metadata from BI V2 instead of BI V1',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  BiReadFromActivityDataset: {
    description:
      'Whether BI should use ActivityDataset rather than legacy activity APIs to load activity data.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  ValueMappingSkipSeedingOnUpload: {
    description:
      'Do not use if you are not on DNO team! This is a temporary flag to cause Value Mapping not to seed right after a file upload completes. It’s meant to alleviate issues caused from a sudden burst of API uploads, which can cause too much DuckDB pressure and some concurrency bugs. Use it in tandem with the manual workflow reseedAllFinancialValueMappingsWorkflow, and turn off the flag after running that workflow. Once we fix those concurrency problems, we can delete this flag.',
    team: Teams.DatasetsAndObjects,
    stage: FlagStage.Alpha,
  },
  OrganizationDestroyWithFkDependencies: {
    description:
      'Enable destroying an organization while relying on FK dependency data',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },
  OrganizationDestroyLogEvents: {
    description: 'Destroy LogEvents when deleting an organization',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },
  SupplyChainAutoMappingDuckDbPagination: {
    description: 'Enable DuckDB pagination for the auto mapping flow.',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  DataLineageFeedback: {
    description: 'Enable data lineage feedback buttons',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },
  UseDirectlyAssignedPermissions: {
    description:
      'Use only directly assigned and role-based permissions in the request context, ignoring the resolved child permissions',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
    featureTags: [FeatureTag.Testing],
  },
  EditFootprintAssemblyJsonConfig: {
    description:
      'Edit the JSON config in footprint assembly in product directly.',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },
  ShowYourWorkNumeratorAndDenominator: {
    description:
      'Enable new UI for Show Your Work in normalized report answers',
    team: Teams.WinCsrd,
    stage: FlagStage.Alpha,
  },
  UseV2SyncPgToParquet: {
    description: 'Use parquet files from V2 of the sync pg to parquet workflow',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },
  SupplyChainManualTargetsUpdatedDesign: {
    description: 'Enable the new UI for manual targets',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  SupplyChainTargetsVsActual: {
    description: 'Enable the targets vs actuals view',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
  },
  ArchiveFootprints: {
    description: 'Q1FY26 -- Enable archiving footprints in product',
    team: Teams.Calcprint,
    stage: FlagStage.Alpha,
  },
  DustBotInProductPrototype: {
    description:
      'INTERNAL ONLY - Enable the Dust Bot in the product prototype.',
    team: Teams.EnterpriseFoundations,
    stage: FlagStage.Alpha,
  },
  AssetManagerRecordAttributionFactorOverride: {
    description:
      'Flag to add attribution factor override to asset holdings in FE and postgresSQL',
    team: Teams.WinFinance,
    stage: FlagStage.Alpha,
  },
  AssetManagerWriteIndustrySectorCodeToBart: {
    description: 'Write the primary industry sector code to finance BART',
    team: Teams.WinFinance,
    stage: FlagStage.Alpha,
  },
  SupplyChainLogarithmicTargetReductionRate: {
    description:
      'Enable logarithmic reduction rate for company climate targets',
    team: Teams.SupplyChain,
    stage: FlagStage.Alpha,
    featureTags: [],
    targetType: TargetType.Organization,
  },
  ProductionGraphUploadMaterialMaster: {
    description: 'Enable material master upload',
    team: Teams.ProductionGraph,
    stage: FlagStage.Alpha,
  },
  CustomerExemptFromAI: {
    description: 'Customer contract prevents AI use',
    team: Teams.AIAdoption,
    stage: FlagStage.Alpha,
    targetType: TargetType.Organization,
  },
};
